<template>
  <faqs use-filter :audiences="[0,1]"></faqs>
</template>

<script>
import Faqs from "@components/shared/Faqs";
export default {
  name: "FaqsSuppliers",
  components: {
    Faqs
  }
}
</script>

<style scoped>

</style>